import {
  getHSRFilterCode,
  getHSRFilters,
  getHSRStarRatingCode,
  getPropertyTypeCode,
} from "src/components/utility/ContextUtil";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

enum HSRFilterNames {
  AMENITIES = "amenities",
  TRAVELER_TYPE = "travelerType",
  SORT = "sort",
  PAYMENT_TYPE = "paymentType",
  PROPERTY_STYLE = "propertyStyle",
}

export function getContextFilterConfig(context: ExtendedContextStore) {
  const hsrFilterCode = getHSRFilterCode(context);
  const hsrFilters = getHSRFilters(context);

  const filters = {
    star: getHSRStarRatingCode(context),
    propertyType: getPropertyTypeCode(context),
    sort: hsrFilters?.[HSRFilterNames.SORT],
    amenity: hsrFilters?.[HSRFilterNames.AMENITIES] || hsrFilterCode,
    paymentType: hsrFilters?.[HSRFilterNames.PAYMENT_TYPE] || hsrFilterCode,
    propertyStyle: hsrFilters?.[HSRFilterNames.PROPERTY_STYLE],
    travelerType: hsrFilters?.[HSRFilterNames.TRAVELER_TYPE],
  };

  return {
    filters,
  };
}

export default getContextFilterConfig;
