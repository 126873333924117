import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading, UitkText } from "@egds/react-core/text";

import { HotelWizardState } from "stores/wizard/state";
import { travelersMetadata, travelersSummary } from "components/shared/TravelersField/utils";
import { getCityWithState } from "../utils/SearchSummaryCardUtils";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export interface SearchSummaryCardProps {
  context: ExtendedContextStore;
  hotelWizardState: HotelWizardState;
  onExpand: () => void;
}

export const SearchSummaryCard: React.FC<SearchSummaryCardProps> = observer(
  ({ context, hotelWizardState, onExpand }) => {
    const { formatDate, formatText } = useLocalization();
    const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      onExpand();
    };

    // Example: Mon, Sep 14
    const summaryDateFormat = { skeleton: "MMMEd" };

    const formattedStartDate = formatDate(hotelWizardState.date.start, summaryDateFormat);
    const formattedEndDate = formatDate(hotelWizardState.date.end, summaryDateFormat);

    const dates = `${formattedStartDate} - ${formattedEndDate}`;

    // context.searchContext.location is populated when LOB is hotel, car, or package
    // context.searchContext.destination is populated when LOB is flight
    const location = context.searchContext.location || context.searchContext.destination;
    const locationName = getCityWithState(location);

    const { numOfAdults, numOfChildren } = travelersMetadata(hotelWizardState.travelers.hotel);
    const travelers = travelersSummary(
      hotelWizardState.config.travelers,
      hotelWizardState.travelers.hotel.rooms,
      numOfAdults + numOfChildren
    );

    const a11yLabel = formatText(
      "hotwire.wizard.summary.a11yLabel",
      locationName,
      formattedStartDate,
      formattedEndDate,
      travelers
    );

    return (
      <UitkCard border>
        <UitkLayoutFlex>
          <UitkLayoutFlexItem alignSelf="center" grow={0}>
            <UitkCardContentSection padded>
              <UitkIcon name="search" />
            </UitkCardContentSection>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem grow={1}>
            <UitkSpacing padding={{ blockstart: "three", blockend: "three", inlineend: "three" }}>
              <UitkCardContentSection padded={false}>
                <UitkHeading tag="h2" size={5} overflow="truncate">
                  {locationName}
                </UitkHeading>
                <UitkLayoutFlex className="SearchSummaryCard-criteria" wrap="wrap">
                  <UitkLayoutFlexItem>
                    <UitkSpacing margin={{ inlineend: "two" }}>
                      <div id="search-summary-card-date-fields">
                        <UitkText size={200} overflow="truncate">
                          {dates}
                        </UitkText>
                      </div>
                    </UitkSpacing>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <UitkText size={200} overflow="truncate">
                      {travelers}
                    </UitkText>
                  </UitkLayoutFlexItem>
                </UitkLayoutFlex>
              </UitkCardContentSection>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
        <UitkCardLink>
          <a href="#" onClick={onClick}>
            {a11yLabel}
          </a>
        </UitkCardLink>
      </UitkCard>
    );
  }
);
