import * as React from "react";
import { InputMapper, InputModel } from "./typings";

function createInputModel(inputs: InputMapper): InputModel[] {
  return Object.keys(inputs).map((name) => {
    const model = inputs[name];
    if (!model) return null;

    const { isVisible, ...input } = model;

    const hasInput = isVisible ?? input.values;

    return [name, hasInput ? input : null];
  });
}

function fromInputModelToDOM(inputs: InputModel[]) {
  return inputs.flatMap((model) => {
    if (!model) return null;

    const [name, input] = model;

    if (!input) return null;

    const { testId, values } = input;

    return values.map((value) => {
      if (!value) return null;

      const key = name + value;

      return <input type="hidden" key={key} name={name} value={value} data-testid={testId} />;
    });
  });
}

/**
 * @params { InputMapper } inputs
 * @return {JSX.Element}
 *
 * This method take a object with any type of key and a value of type InputModel.
 * According with the model, mainly isVisible and value properties, we will render or not a input.
 */
export function renderInputs(inputs: InputMapper) {
  return <>{fromInputModelToDOM(createInputModel(inputs))}</>;
}

export default renderInputs;
