/**
 * If enablePackageRates (default is false) is true for this module, then we show two subTabs under Hotel TAB:
 * Standalone Rates vs Package Rates. When the user is searching from the Standalone Rates
 * everything is the same as the default use case search. When the user is searching from the Package Rates, they need
 * to accept the terms and conditions and that will add this argument to the search URL `packageRates=true`. User can
 * only search hotel in this tab, there should be no car, flight or other filters/add-ons present on the Packager Rates
 * subTab.
 */
import * as React from "react";
import { observer } from "mobx-react";
import { locKeys } from "components/flexComponents/WizardHotelPWA/components/l10n";
import { useLocalization } from "@shared-ui/localization-context";
import {
  UitkExperimentalButtonTabs,
  UitkTab,
  UitkTabAnchorType,
  UitkTabsType,
} from "uitk-react-experimental-button-tabs";
import { HotelRatesType } from "stores/wizard/state/typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkTabProps } from "uitk-react-experimental-button-tabs/dist/uitk-tab";

export interface HotelSubTabsProps {
  currentSubTab: string;
  onTabSelect: (hotelRateType: HotelRatesType) => void;
  renderTabChildren: (
    isInsideMultiLOBPackageWizard: boolean,
    isPackageRatesSubTab: boolean,
    isNewSearchExperience: boolean
  ) => React.ReactElement;
  enablePackageRates: boolean;
}

export class TabProps implements UitkTabProps {
  name: string;

  displayName: string;

  key: string;

  currentSubTab: HotelRatesType;

  isInsideMultiLOBPackageWizard: boolean;

  isPackageRatesSubTab: boolean;

  isNewSearchExperience: boolean;
}

export const HotelSubTabs = observer(
  ({ currentSubTab, onTabSelect, renderTabChildren, enablePackageRates }: HotelSubTabsProps) => {
    const { formatText } = useLocalization();
    const tabIdPrefix = "wizard-hotel-tab-";
    const tabs = [];

    const standaloneTab = new TabProps();
    standaloneTab.name = HotelRatesType.STANDALONE;
    standaloneTab.displayName = formatText(locKeys.hotelStandaloneRates);
    standaloneTab.key = `${tabIdPrefix}${HotelRatesType.STANDALONE}`;
    standaloneTab.currentSubTab = HotelRatesType.STANDALONE;
    standaloneTab.isInsideMultiLOBPackageWizard = false;
    standaloneTab.isPackageRatesSubTab = false;
    standaloneTab.isNewSearchExperience = false;
    tabs.push(standaloneTab);

    if (enablePackageRates) {
      const packageTab = new TabProps();
      packageTab.name = HotelRatesType.PACKAGE_RATES;
      packageTab.displayName = formatText(locKeys.hotelPackageRates);
      packageTab.key = `${tabIdPrefix}${HotelRatesType.PACKAGE_RATES}`;
      packageTab.currentSubTab = HotelRatesType.PACKAGE_RATES;
      packageTab.isInsideMultiLOBPackageWizard = false;
      packageTab.isPackageRatesSubTab = true;
      packageTab.isNewSearchExperience = false;
      tabs.push(packageTab);
    }

    return (
      <UitkSpacing margin={{ blockstart: "three" }}>
        <UitkExperimentalButtonTabs
          className="flightTypeTabs"
          selectedTab={currentSubTab}
          onTabSelect={onTabSelect}
          tabsType={UitkTabsType.NATURAL}
          tabAnchorType={UitkTabAnchorType.BUTTON}
          data-testid={currentSubTab}
        >
          {tabs.map((tab) => {
            return (
              <UitkTab name={tab.name} displayName={tab.displayName} id={tab.key} key={tab.key} targetURI="#">
                {currentSubTab === tab.currentSubTab &&
                  renderTabChildren(
                    tab.isInsideMultiLOBPackageWizard,
                    tab.isPackageRatesSubTab,
                    tab.isNewSearchExperience
                  )}
              </UitkTab>
            );
          })}
        </UitkExperimentalButtonTabs>
      </UitkSpacing>
    );
  }
);
