import * as React from "react";
import { logger } from "bernie-core";
import { inject, observer } from "mobx-react";
import { useQuery } from "@apollo/client";

import { getComponentFromView } from "./utils/WizardHotelPWAv2Utils";
import { WizardHotelPWAv2FlexModuleModelResult, WizardHotelPWAv2Props } from "./typings";
import {
  TravelAgentSearchWizardConfigQueryDocument,
  TravelAgentSearchWizardConfigQueryQueryVariables,
} from "__generated__/typedefs";
import { isVariantEnabled } from "components/utility/experiment";
import { getOffsetDatesByDatesOption } from "src/components/utility/OffsetDate/OffsetDateUtil";
import { SystemEvent, SystemEventLevel } from "@shared-ui/logger-context";
import { useInitializer } from "src/utils/hooks/useInitializer";
import { useBexApi } from "@shared-ui/bex-api-context";
import { getQueryParamValue } from "components/utility/UrlUtils";
import { toBooleanOrUndefined } from "src/components/utility/toBooleanOrUndefined";

const WizardHotelPWAv2Module = (props: WizardHotelPWAv2Props) => {
  const { context, templateComponent, flexModuleModelStore, flexViewModel, analytics, wizardState } = props;
  const { hotelWizardState } = wizardState;
  const {
    metadata: { id },
  } = templateComponent;

  const options = flexModuleModelStore.getModel(id) as WizardHotelPWAv2FlexModuleModelResult;
  const { context: bexApiContext } = useBexApi();
  const travelAgentQueryVariables: TravelAgentSearchWizardConfigQueryQueryVariables = {
    context: bexApiContext,
  };

  const {
    config: { view = "" },
  } = templateComponent;

  const updateFieldsFromConfigAndContext = () => {
    hotelWizardState.updateDateFromConfig();
    if (options.destinationFromContext) {
      hotelWizardState.updateDestinationFromContext(context);
    }
  };

  useInitializer(() => {
    const hotelWizardState = props.wizardState.hotelWizardState;
    const hotelWizardConfig = hotelWizardState.config;
    const { essAdapterConfig } = hotelWizardConfig.location.destination;
    const isDesktop = Boolean(!context.deviceInformation?.mobile && !context.deviceInformation?.tablet);

    hotelWizardState.overrideConfig(() => {
      hotelWizardConfig.elementId = id;
      hotelWizardConfig.heading.fixedTitle = options.fixedLobTitle !== false;
      hotelWizardConfig.heading.title = options.lobTitle;
      hotelWizardConfig.includeFiltersHotelPWAWizard = options.includeFiltersHotelPWAWizard;
      hotelWizardConfig.analytics = analytics;
      hotelWizardConfig.enableMultiplePlaces = options.enableMultiplePlaces;

      if (!hotelWizardConfig.useTravelAgentSearchWizardConfig) {
        hotelWizardConfig.heading.hideLobTitle = options.hideLobTitle;
      }

      hotelWizardConfig.heading.lobSubtitleBreakPoint = options.lobSubtitleBreakPoint;
      hotelWizardConfig.siteIdsWithoutSoftPackages = options.siteIdsWithoutSoftPackages || [];
      hotelWizardConfig.desktopAutoOpenCalendar = Boolean(isDesktop && options.desktopAutoOpenCalendar);

      hotelWizardConfig.enablePackageRates = options.enablePackageRates ?? hotelWizardConfig.enablePackageRates;
      hotelWizardConfig.percentSave = options.percentSave ?? hotelWizardConfig.percentSave;
      hotelWizardConfig.packageRatesTermsUrl = options.packageRatesTermsUrl ?? hotelWizardConfig.packageRatesTermsUrl;
      hotelWizardConfig.showDestinationField = options.showDestinationField ?? hotelWizardConfig.showDestinationField;
      hotelWizardConfig.useTravelAgentSearchWizardConfig =
        options.useTravelAgentSearchWizardConfig ?? hotelWizardConfig.useTravelAgentSearchWizardConfig;

      const currentSiteId = props.context?.site?.id.toString();

      if (
        hotelWizardConfig.siteIdsWithoutSoftPackages.length !== 0 &&
        hotelWizardConfig.siteIdsWithoutSoftPackages.includes(currentSiteId)
      ) {
        hotelWizardConfig.enableAddFlight = false;
        hotelWizardConfig.enableAddCar = false;
      } else {
        hotelWizardConfig.enableAddFlight = options.enableAddFlight;
        hotelWizardConfig.enableAddCar = options.enableAddCar;
      }

      if (flexViewModel && flexViewModel.userLocation) {
        essAdapterConfig.latLong = `${flexViewModel.userLocation.latitude},${flexViewModel.userLocation.longitude}`;
      }

      essAdapterConfig.trending = true;

      essAdapterConfig.historyDetail =
        essAdapterConfig.lob === "HOTELS" && isVariantEnabled(context, "BEX_WEB_Typeahead_History_With_details");

      const { defaultOffsetDates } = hotelWizardConfig.date;
      const { startDateOffset, endDateOffset } = getOffsetDatesByDatesOption(options);

      if (defaultOffsetDates && startDateOffset !== undefined && endDateOffset !== undefined) {
        defaultOffsetDates.start = startDateOffset;
        defaultOffsetDates.end = endDateOffset;
      }
    });

    hotelWizardConfig.travelerType = options.defaultTravelerType;

    const useRewards = toBooleanOrUndefined(getQueryParamValue("useRewards"));
    hotelWizardConfig.enableHotelsPWPToggle = options.showHotelsPWPToggle || useRewards !== undefined;
    if (useRewards !== undefined) {
      hotelWizardState.isPayWithPointsChecked = useRewards;
    }

    updateFieldsFromConfigAndContext();

    if (options.titleFromPageHeader) {
      hotelWizardConfig.heading.differentiatedTitles = false;
    }

    if (options.subTitleFromPageSubHeader) {
      hotelWizardConfig.heading.lobSubtitleBreakPoint = "on";
    }
  });

  const { data, error, loading } = useQuery(TravelAgentSearchWizardConfigQueryDocument, {
    variables: travelAgentQueryVariables,
    skip: hotelWizardState.config.useTravelAgentSearchWizardConfig ? false : true,
    ssr: false,
  });

  if (error) {
    props.logger.logEvent(
      new SystemEvent(SystemEventLevel.ERROR, "FAILED_TRAVEL_AGENT_FETCH"),
      "Failed to fetch travel agent configuration",
      error.message
    );
  }

  if (!loading && data) {
    hotelWizardState.updateFromTravelAgentConfig(data.travelAgentSearchWizardConfig);
  }

  const ViewComponent = getComponentFromView(view);

  return <ViewComponent {...props} />;
};

export const WizardHotelPWAv2 = logger("WizardHotelPWAv2")(
  inject(
    "analytics",
    "experiment",
    "page",
    "wizardState",
    "compositionStore",
    "flexModuleModelStore",
    "flexViewModel",
    "cookies"
  )(observer(WizardHotelPWAv2Module))
);

export default WizardHotelPWAv2;
