import * as React from "react";
import { observer } from "mobx-react";

import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";

import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { Travelers } from "components/shared/Travelers/Travelers";

import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";

import { WizardHotelPWAv2Props } from "../../typings";
import { locKeys } from "components/flexComponents/WizardHotelPWA/components/l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { CheckboxModuleNameTypes } from "src/components/shared/PropertyFilterComponents/components/Checkbox";

import { SubmitButton } from "src/components/shared/SubmitButton/SubmitButton";
import { HotelHiddenSearchData } from "src/components/flexComponents/WizardHotelPWAv2/components/HotelHiddenSearchData/HotelHiddenSearchData";
import { InputMapper } from "src/components/flexComponents/WizardHotelPWAv2/components/HotelHiddenSearchData/typings";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { UitkSpacing } from "@egds/react-core/spacing";
import { useStickyElement } from "components/shared/StackedSticky/StackedSticky";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import {
  DateState,
  getLocError,
  NO_REHYDRATATION_VALUES,
  validate,
  getErrorList,
} from "../../utils/WizardHotelPWAv2Utils";
import { UitkHeading } from "@egds/react-core/text";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const STATIC_INPUTS: InputMapper = {
  rfrr: {
    values: ["hotel.search"],
  },
  GOTO: {
    values: ["HOTSEARCH"],
  },
  SearchType: {
    values: ["Place"],
  },
  SearchArea: {
    values: ["City"],
  },
  needUTF8Decode: {
    values: ["true"],
  },
};

export interface WizardFormProps
  extends Pick<WizardHotelPWAv2Props, "templateComponent" | "wizardState" | "analytics" | "context"> {
  isDesktop: boolean;
  flexModuleModelStore: FlexModuleModelStore;
  desktopAutoOpenCalendar?: boolean;
  canHaveEmptyDates?: boolean;
}

export const WizardForm: React.FC<WizardFormProps> = observer((props) => {
  const { context, wizardState, templateComponent, isDesktop, desktopAutoOpenCalendar, canHaveEmptyDates } = props;
  const {
    globalWizardState,
    hotelWizardState,
    hotelWizardState: { config, numberOfErrors, errorInputRef, moreThanOneError },
  } = wizardState;

  const { elementId, showDestinationField } = config;

  const { formatText } = useLocalization();
  const track = useClickTracker();

  const {
    config: { fmTitleId },
  } = templateComponent;

  const mainTitleWizardClassNames: UitkLayoutConditionalGridSpan = { medium: 12, large: 12 };
  const cardColSpan: UitkLayoutConditionalGridSpan = { medium: 4, large: 12 };
  const mainGridColumns: UitkLayoutConditionalGridTrack = { medium: 4, large: 8 };
  const locationColSpan: UitkLayoutConditionalGridSpan = { medium: 4, large: 2 };
  const datesColSpan: UitkLayoutConditionalGridSpan = { medium: 2, large: 3 };
  const travelersColSpan: UitkLayoutConditionalGridSpan = { medium: 1, large: 2 };
  const submitColSpan: UitkLayoutConditionalGridSpan = { medium: 1, large: 1 };

  const [htgStartInvalidMessage, setStartInvalidMessage] = React.useState<string>("");
  const [htgEndInvalidMessage, setEndInvalidMessage] = React.useState<string>("");

  const { isSticky } = useStickyElement();

  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(Boolean(isDesktop && desktopAutoOpenCalendar));
  const hasEmptyDates = canHaveEmptyDates ?? true;

  React.useEffect(() => {
    // this prevent the new date picker to stay opened when switching between sticky/non-sticky wizard
    if (isSticky) {
      setDatePickerIsOpen(false);
    }
  }, [isSticky]);

  /* istanbul ignore next */
  const toggleDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  if (!hotelWizardState.regionId) {
    hotelWizardState.setRegionId(context.searchContext.location.id);
  }

  const setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
    hotelWizardState.wizardInputsArray.push(ref);
    if (secondRef) {
      hotelWizardState.wizardInputsArray.push(secondRef);
    }
  };

  /* istanbul ignore next */
  const validateAndGetFormErrors = (event?: React.FormEvent<HTMLFormElement>) => {
    const startDateError = validate(DateState.START, hotelWizardState, formatText, hasEmptyDates);
    const endDateError = validate(DateState.END, hotelWizardState, formatText, hasEmptyDates);

    setStartInvalidMessage(startDateError);
    setEndInvalidMessage(endDateError);

    return getErrorList(startDateError, endDateError, event);
  };

  /* istanbul ignore next */
  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    if (validateAndGetFormErrors().length > 0) {
      e.preventDefault();
    }
  };

  /* istanbul ignore next */
  const submitForm = () => {
    if (validateAndGetFormErrors().length === 0) {
      const flexTracking: FlexTrackingInfo = {
        moduleName: "wizard-hotel-pwa-v2",
        rfrr: "HTG.Datepicker.Apply_Dates",
        action: Action.CLICK,
      };
      sendDelayedClickstreamTrackEvent(flexTracking, track);

      /* istanbul ignore next */
      wizardFormRef?.current?.submit();
    }
  };

  const wizardFormRef = React.useRef<HTMLFormElement>(null);

  const wizardFormTestId = isSticky ? "wizard-sticky" : "wizard-form";

  return (
    <UitkLayoutGrid columns={mainGridColumns}>
      <UitkLayoutGridItem colSpan={cardColSpan}>
        <UitkSpacing padding={{ medium: { inline: "three", block: isSticky ? "three" : "six" } }}>
          <form
            noValidate
            id={elementId}
            data-fm={getFmId(templateComponent)}
            data-fm-title-id={fmTitleId}
            onSubmit={onSubmitForm}
            action={hotelWizardState.formAction}
            ref={wizardFormRef}
            data-testid={wizardFormTestId}
          >
            <UitkLayoutGrid columns={mainGridColumns} space="three">
              {numberOfErrors > 0 && (
                <LobErrorSummary
                  locHeadingToken={getLocError(false, hotelWizardState)!}
                  locHeadingArgs={moreThanOneError() && [numberOfErrors]}
                  locLinkToken={getLocError(true, hotelWizardState)}
                  linkClickFocusId="roundtrip-error"
                  inputErrorRef={errorInputRef}
                  colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
                  setInputsRefs={setInputsRefs}
                />
              )}
              {isSticky && isDesktop && (
                <UitkLayoutGridItem colSpan={mainTitleWizardClassNames}>
                  <UitkHeading tag="h1" size={5} role="heading" className="is-visually-hidden">
                    {formatText(locKeys.stickyWizardTitle)}
                  </UitkHeading>
                </UitkLayoutGridItem>
              )}
              {showDestinationField && (
                <Location
                  originField={false}
                  destinationField
                  lobState={hotelWizardState}
                  colSpan={locationColSpan}
                  setInputsRefs={setInputsRefs}
                  globalConfig={globalWizardState.config}
                />
              )}
              <Dates
                lobState={hotelWizardState}
                colSpan={datesColSpan}
                setInputsRefs={setInputsRefs}
                autoOpenCalendar={datePickerIsOpen}
                toggleDatePicker={toggleDatePicker}
                isDesktop={isDesktop}
                htgStartInvalidMessage={htgStartInvalidMessage}
                htgEndInvalidMessage={htgEndInvalidMessage}
                validateAndGetFormErrors={validateAndGetFormErrors}
                submitForm={submitForm}
                hasEmptyDates={hasEmptyDates}
              />

              <Travelers
                lobState={hotelWizardState}
                colSpan={travelersColSpan}
                noRehydratationValues={NO_REHYDRATATION_VALUES}
                submitForm={submitForm}
              />

              <SubmitButton
                lobState={hotelWizardState}
                colSpan={submitColSpan}
                rfrr="SearchClick_Hotel"
                globalConfig={globalWizardState.config}
                moduleName={CheckboxModuleNameTypes.WIZARD_HOTEL_V2_ROW}
                testId="wizard-submit-button"
              />
            </UitkLayoutGrid>
            <HotelHiddenSearchData
              hotelWizardState={hotelWizardState}
              staticInputs={{
                ...STATIC_INPUTS,
                lang: {
                  isVisible: Boolean(context.langId),
                  values: [String(context.langId)],
                },
              }}
            />
          </form>
        </UitkSpacing>
      </UitkLayoutGridItem>
    </UitkLayoutGrid>
  );
});

export default WizardForm;
