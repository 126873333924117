import { HSRFilterModel } from "../typings";
import { locKeys } from "./l10n";

enum WizardHotelFilterOptions {
  BUSINESS_FRIENDLY = "BUSINESS",
  FAMILY_FRIENDLY = "FAMILY",
  FREE_BREAKFAST = "FREE_BREAKFAST",
  FREE_CANCELLATION = "FREE_CANCELLATION",
}

interface HSRFilterProps {
  [key: string]: HSRFilterModel;
}

export const WizardHotelFilterType: HSRFilterProps = {
  businessFriendly: {
    id: "travelerTypeBussiness",
    key: "businessFriendly",
    filterTypeKey: locKeys.filterBusinessFriendly,
    name: "travelerType",
    value: WizardHotelFilterOptions.BUSINESS_FRIENDLY,
  },
  freeCancellation: {
    id: "paymentTypeFreeCancellation",
    key: "freeCancellation",
    filterTypeKey: locKeys.filterFreeCancellation,
    name: "paymentType",
    value: WizardHotelFilterOptions.FREE_CANCELLATION,
  },
  familyFriendly: {
    id: "travelerTypeFamily",
    key: "familyFriendly",
    filterTypeKey: locKeys.filterFamilyFriendly,
    name: "travelerType",
    value: WizardHotelFilterOptions.FAMILY_FRIENDLY,
  },
  breakfastIncluded: {
    id: "amenitiesFreeBreakfast",
    key: "breakfastIncluded",
    filterTypeKey: locKeys.filterBreakfastIncluded,
    name: "amenities",
    value: WizardHotelFilterOptions.FREE_BREAKFAST,
  },
};
