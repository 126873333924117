import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import * as React from "react";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "src/components/utility/analytics/FlexAnalyticsUtils";

const getTrackingConfig = (isExpanded: boolean): FlexTrackingInfo => {
  const key = isExpanded ? "open" : "close";

  return {
    moduleName: "wizard-hotel-pwa-v2",
    action: Action.CLICK,
    linkName: `${key} wizard v2 expando`,
    rfrr: `expando.${key}`,
  };
};

export const useExpando = (defaultState: boolean) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultState);
  const track = useClickTracker();

  const toggle = () => {
    setIsExpanded((isExp) => {
      const nextState = !isExp;
      const trackInfo = getTrackingConfig(nextState);

      sendDelayedClickstreamTrackEvent(trackInfo, track);

      return nextState;
    });
  };

  const expand = () => {
    setIsExpanded(true);
  };

  return {
    toggle,
    isExpanded,
    expand,
  };
};
