import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { InputMapper } from "../typings";
import { Filter } from "src/components/flexComponents/PropertyFilters/typings";

export function generateInputsModel(filters: { [key: string]: string[] }) {
  const inputModels: InputMapper = {};

  Object.keys(filters).forEach((filterKey) => {
    inputModels[filterKey] = {
      values: filters[filterKey],
    };
  });

  return inputModels;
}

function generatePropertyFiltersMap(propertyFilters: PropertyFiltersStore) {
  const list: Filter[] = [
    ...propertyFilters.getListOfFilters(),
    propertyFilters.getSort({ force: true }),
  ].filter((f): f is Filter => Boolean(f));

  const filters: { [key: string]: string[] } = {};
  list.forEach((filter) => {
    const selectedFilters = Array.from(filter.options.values())
      .filter((option) => option.isSelected)
      .map((option) => option.optionValue)
      .filter((value): value is string => Boolean(value));

    if (selectedFilters.length > 0) {
      filters[filter.name] = selectedFilters;
    }
  });

  return filters;
}

export function getPropertyFilterConfig(propertyFilters: PropertyFiltersStore) {
  const filters = generatePropertyFiltersMap(propertyFilters);

  return {
    filters,
    inputs: generateInputsModel(filters),
  };
}

export default getPropertyFilterConfig;
