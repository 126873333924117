import * as React from "react";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { UitkExpandoLink } from "@egds/react-core/expando";
import { UitkLinkSizes } from "@egds/react-core/link";
import { UitkLayoutGrid, UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { locKeys } from "./l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { WizardHotelFilterType } from "./WizardHotelFilterType";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { withStores } from "stores";
import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { UitkSpacing } from "@egds/react-core/spacing";

const WizardHotelFilterList = [
  WizardHotelFilterType.businessFriendly,
  WizardHotelFilterType.freeCancellation,
  WizardHotelFilterType.familyFriendly,
  WizardHotelFilterType.breakfastIncluded,
];
const WizardHotelFilterListMobile = [
  WizardHotelFilterType.businessFriendly,
  WizardHotelFilterType.familyFriendly,
  WizardHotelFilterType.freeCancellation,
  WizardHotelFilterType.breakfastIncluded,
];

interface WizardHotelPWAFilterProps {
  checkboxFilterChecks: object;
  changeFormProperties?: (event: React.ChangeEvent<HTMLFormElement>) => void;
  advancedOptionsFilterVisible?: boolean;
  propertyFilters: PropertyFiltersStore;
  context?: ExtendedContextStore;
  popularFiltersColSpan?: UitkLayoutConditionalGridSpan;
}

export const WizardHotelPWAFilter = withStores("propertyFilters")((props: WizardHotelPWAFilterProps) => {
  const { checkboxFilterChecks, changeFormProperties, advancedOptionsFilterVisible } = props;
  const { formatText } = useLocalization();
  const title = formatText(locKeys.filterAdvancedOptionsLink);
  const [isExpandoVisible, setIsExpandoVisible] = React.useState(Boolean(advancedOptionsFilterVisible));

  return (
    <UitkLayoutFlex space="three">
      <UitkExpandoLink
        size={UitkLinkSizes.get("medium")}
        expandoTitle={title}
        isVisible={isExpandoVisible}
        onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
      >
        <Viewport>
          <ViewSmall>
            <>
              {WizardHotelFilterListMobile.map(({ id, key, filterTypeKey, name, value }) => (
                <UitkCheckbox
                  id={id}
                  name={name}
                  checked={checkboxFilterChecks[key as keyof object]}
                  onChange={changeFormProperties}
                  value={value}
                  key={key}
                >
                  {formatText(filterTypeKey)}
                </UitkCheckbox>
              ))}
            </>
          </ViewSmall>
          <ViewMedium>
            <UitkLayoutGrid columns={2}>
              {WizardHotelFilterList.map(({ id, key, filterTypeKey, name, value }) => (
                <UitkLayoutGridItem key={key}>
                  <UitkSpacing padding={{ inlineend: "six" }}>
                    <div>
                      <UitkCheckbox
                        id={id}
                        name={name}
                        checked={checkboxFilterChecks[key as keyof object]}
                        onChange={changeFormProperties}
                        value={value}
                      >
                        {formatText(filterTypeKey)}
                      </UitkCheckbox>
                    </div>
                  </UitkSpacing>
                </UitkLayoutGridItem>
              ))}
            </UitkLayoutGrid>
          </ViewMedium>
        </Viewport>
      </UitkExpandoLink>
    </UitkLayoutFlex>
  );
});
