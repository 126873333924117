import { observer } from "mobx-react";
import * as React from "react";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkSwitch } from "@egds/react-core/switch";
import { HotelWizardStateProps } from "components/flexComponents/WizardHotelPWAv2/typings";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useLocalization } from "@shared-ui/localization-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

export const HotelAddPWPToggle = observer(({ hotelWizardState }: HotelWizardStateProps) => {
  const { formatText } = useLocalization();

  const track = useClickTracker();

  const getTrackingConfig = (isPayWithPointsChecked: boolean): FlexTrackingInfo => {
    const key = isPayWithPointsChecked;

    return {
      moduleName: "wizard-hotel-pwa-v2",
      action: Action.CLICK,
      linkName: `${key} wizard v2 expando`,
      rfrr: `pwpToggle.${key}`,
    };
  };

  const handleChange = () => {
    hotelWizardState.togglePayWithPointsChecked();
    const trackInfo = getTrackingConfig(hotelWizardState.isPayWithPointsChecked);
    sendDelayedClickstreamTrackEvent(trackInfo, track);
  };

  const changeLabelOnToggle = () => {
    if (hotelWizardState.isPayWithPointsChecked)
      return formatText(hotelWizardState.config.payWithPointsCheckedToken as string);
    return formatText(hotelWizardState.config.payWithCashCheckedToken as string);
  };
  return (
    <UitkSpacing margin={{ blockstart: "one" }}>
      <div data-testid="pwp-toggle">
        <UitkSwitch
          id="pwpToggle"
          checked={hotelWizardState.isPayWithPointsChecked}
          onChange={handleChange}
          name="rewards toggle"
          switchPosition="trailing"
          value={String(hotelWizardState.isPayWithPointsChecked)}
        >
          {changeLabelOnToggle()}
        </UitkSwitch>
      </div>
    </UitkSpacing>
  );
});
