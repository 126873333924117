import { observer } from "mobx-react";
import * as React from "react";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkSwitch } from "@egds/react-core/switch";
import { HotelWizardStateProps } from "components/flexComponents/WizardHotelPWAv2/typings";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useLocalization } from "@shared-ui/localization-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";

export const HotelPackageRatesToggle = observer(({ hotelWizardState }: HotelWizardStateProps) => {
  const track = useClickTracker();
  const { formatText } = useLocalization();

  const getTrackingConfig = (isPackageRatesIncluded: boolean): FlexTrackingInfo => {
    const key = isPackageRatesIncluded;

    return {
      moduleName: "wizard-hotel-pwa-v2",
      action: Action.CLICK,
      linkName: `${key} wizard v2 expando`,
      rfrr: `pkgToggle.${key}`,
    };
  };

  const handleChange = () => {
    hotelWizardState.togglePackageRatesIncluded();
    const trackInfo = getTrackingConfig(hotelWizardState.isPackageRatesIncluded);
    sendDelayedClickstreamTrackEvent(trackInfo, track);
  };

  React.useEffect(() => {
    hotelWizardState.setPackageRatesToggleVisible();
  });

  return (
    <UitkSpacing margin={{ block: "three" }}>
      <UitkLayoutGridItem colSpan={5}>
        <div data-testid="pkg-toggle">
          <UitkSpacing margin={{ block: "two" }}>
            <div>
              <UitkSwitch
                a11yLabel={formatText("wizard.search.includepackagerates.a11yLabel")}
                checked={hotelWizardState.isPackageRatesIncluded}
                onChange={handleChange}
                description={formatText("wizard.search.includepackagerates.description")}
              >
                {formatText("wizard.search.includepackagerates.a11yLabel")}
              </UitkSwitch>
            </div>
          </UitkSpacing>
        </div>
      </UitkLayoutGridItem>
    </UitkSpacing>
  );
});
