import * as React from "react";
import { observer } from "mobx-react";

import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import {
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
  UitkLayoutGrid,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";

import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { Travelers } from "components/shared/Travelers/Travelers";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { SearchSummaryCard } from "../components/SearchSummaryCard";
import { useHotwireHotelGeolocationOverrides, useHotwireHotelOverrides } from "../hooks/useHotwireHotelOverrides";
import { getLocError, NO_REHYDRATATION_VALUES } from "../utils/WizardHotelPWAv2Utils";
import { WizardHotelPWAv2FlexModuleModelResult, WizardHotelPWAv2Props } from "../typings";
import { getFmId } from "src/stores/ExperienceTemplateStore";

export const WizardHotelPWAv2HotwireView: React.FunctionComponent<WizardHotelPWAv2Props> = observer(
  ({ context, templateComponent, flexModuleModelStore, wizardState: { hotelWizardState } }) => {
    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const { config, numberOfErrors, errorInputRef, moreThanOneError } = hotelWizardState;
    const model = (flexModuleModelStore.getModel(id) as WizardHotelPWAv2FlexModuleModelResult) || null;

    if (!model) {
      return null;
    }

    useHotwireHotelOverrides(hotelWizardState);
    useHotwireHotelGeolocationOverrides(hotelWizardState, model.defaultToUserLocation);
    const [isMobileSummary, setMobileSummary] = React.useState(model.destinationFromContext);
    const { isMultiLOB } = useMultiLOBWizardRegion();

    const expandMobileSummary = () => setMobileSummary(false);

    const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 4, large: 12 };

    const locationColSpan: UitkLayoutConditionalGridSpan = { small: 4, large: isMultiLOB ? 4 : 3 };
    const datesColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 2, large: 3 };
    const travelersColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 2, large: 3 };
    const submitColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: isMultiLOB ? 4 : 1, large: 2 };

    const setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
      hotelWizardState.wizardInputsArray.push(ref);
      if (secondRef) {
        hotelWizardState.wizardInputsArray.push(secondRef);
      }
    };

    /**
     * Render the form elements so that the submit button, which is shown even when
     * the mobile search summary card is shown, always links to the default search.
     */
    const renderForm = (isFormVisible: boolean) => {
      const contents = (
        <>
          {numberOfErrors > 0 && (
            <LobErrorSummary
              locHeadingToken={getLocError(false, hotelWizardState)!}
              locHeadingArgs={moreThanOneError() && [numberOfErrors]}
              locLinkToken={getLocError(true, hotelWizardState)}
              linkClickFocusId="roundtrip-error"
              inputErrorRef={errorInputRef}
              colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
              setInputsRefs={setInputsRefs}
            />
          )}
          <Location
            originField={false}
            destinationField
            lobState={hotelWizardState}
            colSpan={locationColSpan}
            setInputsRefs={setInputsRefs}
          />
          <Dates
            useHiddenInputs={false}
            startId="startDate"
            endId="endDate"
            lobState={hotelWizardState}
            colSpan={datesColSpan}
            setInputsRefs={setInputsRefs}
          />
          <Travelers
            lobState={hotelWizardState}
            colSpan={travelersColSpan}
            noRehydratationValues={NO_REHYDRATATION_VALUES}
          />
        </>
      );

      return (
        <form
          noValidate
          className="WizardHotelPWAHotwire"
          id={config.elementId}
          data-fm={fmId}
          data-fm-title-id={fmTitleId}
          onSubmit={hotelWizardState.submit}
          action={hotelWizardState.formAction}
        >
          <UitkSpacing margin={{ blockstart: "three" }}>
            <UitkLayoutGrid columns={mainGridColumns} space="three">
              {isFormVisible ? contents : <div style={{ display: "none" }}>{contents}</div>}
              <SubmitButton lobState={hotelWizardState} colSpan={submitColSpan} />
            </UitkLayoutGrid>
          </UitkSpacing>
        </form>
      );
    };

    return (
      <Viewport>
        <ViewSmall>
          <>
            {isMobileSummary && (
              <SearchSummaryCard context={context} hotelWizardState={hotelWizardState} onExpand={expandMobileSummary} />
            )}
            {renderForm(!isMobileSummary)}
          </>
        </ViewSmall>
        <ViewMedium>{renderForm(true)}</ViewMedium>
      </Viewport>
    );
  }
);
