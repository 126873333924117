import { Geo } from "typings/flexFramework/FlexDefinitions";
import { LocationType } from "components/utility/LocationUtils";

/**
 * Formats a location to include its province/state code if available. (Montreal, QC)
 * Returns only the city name if unavailable. (Montreal)
 * @param location Location from the Flex search context
 */
export const getCityWithState = (location: Geo) => {
  const hasParentProvince = location.parent?.type === LocationType.PROVINCE_STATE;

  if (!hasParentProvince) {
    return location.name;
  }

  const provinceCode = location.parent?.code?.split("-")?.[1];

  return provinceCode ? `${location.name}, ${provinceCode}` : location.name;
};
