import * as React from "react";
import { observer } from "mobx-react";

import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { WizardHotelPWAv2FlexModuleModelResult, WizardHotelPWAv2Props } from "../../typings";
import { WizardForm } from "./WizardForm";
import { WizardMobile } from "./WizardMobile";
import { StackedSticky } from "components/shared/StackedSticky/StackedSticky";

export const WizardHotelPWAv2RowView: React.FC<WizardHotelPWAv2Props> = observer((props) => {
  const { context, wizardState, analytics, templateComponent, flexModuleModelStore } = props;

  const {
    metadata: { id },
  } = templateComponent;

  const { desktopAutoOpenCalendar, canHaveEmptyDates } = flexModuleModelStore.getModel(
    id
  ) as WizardHotelPWAv2FlexModuleModelResult;

  const wizardFormProps = {
    templateComponent,
    wizardState,
    analytics,
    context,
    flexModuleModelStore,
    desktopAutoOpenCalendar,
    canHaveEmptyDates,
  };

  // TODO: Implement StackedSticky on WizardMobile component as well as we are
  // doing on medium and mayor devices

  return (
    <div className="WizardHotelPWAv2Row WizardHotelPWA">
      <Viewport>
        <ViewSmall>
          <WizardMobile {...wizardFormProps} isDesktop={false} />
        </ViewSmall>
        <ViewMedium>
          <StackedSticky>
            <WizardForm {...wizardFormProps} isDesktop />
          </StackedSticky>
        </ViewMedium>
      </Viewport>
    </div>
  );
});

export default WizardHotelPWAv2RowView;
