import { HotelWizardState } from "src/stores/wizard/state";
import { Input } from "./typings";

type ReturnedInput = Input | null | undefined;

export function getSort(isVacationRental: boolean, contextSort?: string, filterSort?: string[]): ReturnedInput {
  if (isVacationRental) {
    return {
      isVisible: true,
      testId: "vr-sort",
      values: ["vacationRental", ...(filterSort || [])],
    };
  }

  if (contextSort || filterSort) {
    return {
      isVisible: true,
      values: filterSort || [contextSort],
    };
  }

  return null;
}

export function getRegionId(hotelWizardState: HotelWizardState, neighborhoods?: string[]): ReturnedInput {
  const typeaheadRegionId = hotelWizardState.location.destination.metaData.regionId;
  const hotelRegionId = hotelWizardState.regionId;

  if (neighborhoods && typeaheadRegionId === hotelRegionId) {
    return {
      testId: "regionId",
      values: neighborhoods,
    };
  }

  return {
    testId: "regionId",
    values: [typeaheadRegionId || hotelRegionId],
  };
}

export default {
  getSort,
  getRegionId,
};
