import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";
import { ViewLarge, ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import {
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
  UitkLayoutGrid,
  UitkLayoutGridItem,
} from "@egds/react-core/layout-grid";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

import { MultiLOBPackageWizardContext } from "src/components/flexComponents/PageLevelProviders/WizardContextProvider/MultiLOBPackageWizardContext";

import { LobTitle } from "components/shared/LobTitle/LobTitle";
import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { Travelers } from "components/shared/Travelers/Travelers";

import { WizardHotelPWAFilter } from "components/flexComponents/WizardHotelPWA/components/WizardHotelPWAFilter";
import { LobSubtitle } from "components/shared/LobSubtitle/LobSubtitle";
import { SubmitRow } from "components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { locKeys } from "components/flexComponents/WizardHotelPWA/components/l10n";

import { HotelAddFlightCheckbox } from "../components/HotelAddFlightCheckbox";
import { HotelAddCarCheckbox } from "../components/HotelAddCarCheckbox";
import { HotelHiddenSearchData } from "../components/HotelHiddenSearchData/HotelHiddenSearchData";
import { HotelPackageRatesTermsCheckbox } from "../components/HotelPackageRatesTermsCheckbox";
import { MultiplePlaces } from "../components/MultiplePlaces";

import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";
import { HotelRatesType, PackagesSubLobs } from "stores/wizard/state/typings";
import { WizardHotelPWAv2FlexModuleModelResult, WizardHotelPWAv2Props } from "../typings";
import { Columns } from "components/shared/StorefrontWizard/typings";
import { HotelSubTabs } from "../components/HotelSubTabs";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { getLocError, NO_REHYDRATATION_VALUES, onAddFlightCheckboxChange } from "../utils/WizardHotelPWAv2Utils";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import {
  Experiment as ExperimentComponent,
  ExperimentControl,
  ExperimentVariant,
  useExperiment,
} from "@shared-ui/experiment-context";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";
import { HotelAddPWPToggle } from "../components/HotelAddPWPToggle";
import { HotelPackageRatesToggle } from "../components/HotelPackageRatesToggle";

export const WizardHotelPWAv2View: React.FunctionComponent<WizardHotelPWAv2Props> = observer((props) => {
  const { formatDate, formatText } = useLocalization();
  const track = useEgClickstream();
  const {
    globalWizardState,
    hotelWizardState,
    hotelWizardState: { config, numberOfErrors, errorInputRef, moreThanOneError },
  } = props.wizardState;
  const { templateComponent, flexModuleModelStore, page, cookies } = props;

  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const model = (flexModuleModelStore.getModel(id) as WizardHotelPWAv2FlexModuleModelResult) || null;
  const options = model;

  const {
    elementId,
    enableAddFlight,
    enableAddCar,
    enablePackageRates,
    percentSave,
    packageRatesTermsUrl,
    enableMultiplePlaces,
    desktopAutoOpenCalendar,
    showDestinationField,
    enableHotelsPWPToggle,
  } = config;
  const isBiasedWizardEmpty = enablePackageRates ? true : hotelWizardState.config?.isBiasedWizardEmpty;
  const displayTravelersInline = !enablePackageRates;
  const { halfWizard } = globalWizardState.config;
  const isAgent = cookies?.getCookie("actx") ?? undefined;
  const isMobileDevice = props.context.deviceInformation?.mobile;
  const siteId = props.context.site.id;

  const getExposureAndLogExperiment = (siteId: number) => {
    switch (siteId) {
      case 1070901:
        return useExperiment("TAAP_Unified_Shopping_Path_Switch_Blossom_Flex_UI");
      case 1070903:
        return useExperiment("TAAP_New_Shopping_Experience_ExpUserId_Path_Switch");
      case 1070906:
        return useExperiment("Taapestry_Migration_Test_AB_DE");
      case 1070911:
        return useExperiment("Taapestry_Migration_Test_AB_NL");
      case 1070912:
        return useExperiment("Taapestry_Migration_Test_AB_MX");
      case 1070908:
        return useExperiment("Taapestry_Migration_Test_AB_IT");
      case 1070909:
        return useExperiment("Taapestry_Migration_Test_AB_ES");
      case 1070928:
        return useExperiment("Taapestry_Migration_Test_AB_JP");
      case 1070904:
        return useExperiment("Taapestry_Migration_Test_AB_CA");
      case 1070914:
        return useExperiment("Taapestry_Migration_Test_AB_SG");
      case 1070915:
        return useExperiment("Taapestry_Migration_Test_AB_MY");
      case 1070916:
        return useExperiment("Taapestry_Migration_Test_AB_KR");
      case 1070917:
        return useExperiment("Taapestry_Migration_Test_AB_TH");
      case 1070918:
        return useExperiment("Taapestry_Migration_Test_AB_HK");
      case 1070920:
        return useExperiment("Taapestry_Migration_Test_AB_FR");
      case 1070925:
        return useExperiment("Taapestry_Migration_Test_AB_AU");
      case 1070927:
        return useExperiment("Taapestry_Migration_Test_AB_IN");
      case 1070929:
        return useExperiment("Taapestry_Migration_Test_AB_NZ");
      case 1070975:
        return useExperiment("Taapestry_Migration_Test_AB_CN");
      case 1070973:
        return useExperiment("Taapestry_Migration_Test_AB_FI");
      case 1070972:
        return useExperiment("Taapestry_Migration_Test_AB_CH");
      case 1070971:
        return useExperiment("Taapestry_Migration_Test_AB_VN");
      case 1070970:
        return useExperiment("Taapestry_Migration_Test_AB_AR");
      case 1070969:
        return useExperiment("Taapestry_Migration_Test_AB_BR");
      case 1070968:
        return useExperiment("Taapestry_Migration_Test_AB_PH");
      case 1070967:
        return useExperiment("Taapestry_Migration_Test_AB_DK");
      case 1070966:
        return useExperiment("Taapestry_Migration_Test_AB_NO");
      case 1070965:
        return useExperiment("Taapestry_Migration_Test_AB_SE");
      case 1070964:
        return useExperiment("Taapestry_Migration_Test_AB_BE");
      case 1070963:
        return useExperiment("Taapestry_Migration_Test_AB_IE");
      case 1070962:
        return useExperiment("Taapestry_Migration_Test_AB_TW");
      case 1070961:
        return useExperiment("Taapestry_Migration_Test_AB_ID");
      default:
        return null;
    }
  };

  const exposureAndLogExperiment = getExposureAndLogExperiment(siteId);
  let enableNewTaapShopExperience = false;
  if (exposureAndLogExperiment) {
    const {
      exposure: newTaapShopExperienceExposure,
      logExperiment: newTaapShopExperienceLogExperiment,
    } = exposureAndLogExperiment;

    if (!isMobileDevice && newTaapShopExperienceExposure.bucket > 0) {
      enableNewTaapShopExperience = true;
    }

    React.useEffect(() => {
      try {
        newTaapShopExperienceLogExperiment(newTaapShopExperienceExposure);
      } catch (e) {
        // noop
      }
    }, []);
  }

  const locationColumns: Columns = {
    fullWidth: {
      small: 2,
      large: displayTravelersInline ? 7 : 5,
    },
    half: {
      small: 2,
      large: 12,
    },
  };
  const datesColumns: Columns = {
    fullWidth: {
      small: 2,
      large: displayTravelersInline ? 5 : 4,
    },
    half: {
      small: 2,
      large: 12,
    },
  };
  const travelersColumns: Columns = {
    fullWidth: {
      small: 2,
      large: 3,
    },
    half: {
      small: 2,
      large: 2,
    },
  };
  const addFlightColumn: Columns = {
    fullWidth: {
      small: 1,
      large: 2,
    },
    half: {
      small: 1,
      large: 4,
    },
  };
  const addCarColumn: Columns = {
    fullWidth: {
      small: 1,
      large: 2,
    },
    half: {
      small: 1,
      large: 4,
    },
  };
  const multiplePlacesColumn: Columns = {
    fullWidth: {
      small: 2,
      large: 3,
    },
    half: {
      small: 2,
      large: 12,
    },
  };
  const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 2, large: 12 };
  const datesColSpan = datesColumns[halfWizard ? "half" : "fullWidth"];
  const locationColSpan = locationColumns[halfWizard ? "half" : "fullWidth"];
  const travelersColSpan: UitkLayoutConditionalGridSpan = travelersColumns[halfWizard ? "half" : "fullWidth"];
  const addFlightColSpan: UitkLayoutConditionalGridSpan = addFlightColumn[halfWizard ? "half" : "fullWidth"];
  const addCarColSpan: UitkLayoutConditionalGridSpan = addCarColumn[halfWizard ? "half" : "fullWidth"];
  const multiplePlacesColSpan: UitkLayoutConditionalGridSpan = multiplePlacesColumn[halfWizard ? "half" : "fullWidth"];
  const hotelFiltersColSpan: UitkLayoutConditionalGridSpan = { small: 2, large: 8 };

  const isMultiplePlacesDisabled = hotelWizardState.isAddACarChecked || hotelWizardState.isAddAFlightChecked;
  const wizardStateAddFlight = hotelWizardState.isAddAFlightChecked;

  const [isAddFlightActive, updateAddAFlight] = React.useState(wizardStateAddFlight);

  React.useEffect(() => {
    updateAddAFlight(wizardStateAddFlight);
  }, [wizardStateAddFlight]);

  const disclaimerMultiplePlacesLocKey = isMultiplePlacesDisabled
    ? hotelWizardState.config.useDialogTextWithoutCar
      ? locKeys.dialogTextWithoutCar
      : locKeys.dialogText
    : "";

  const [autoOpenCalendar, setAutoOpenCalendar] = React.useState<boolean>(Boolean(desktopAutoOpenCalendar));

  const setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
    hotelWizardState.wizardInputsArray.push(ref);
    if (secondRef) {
      hotelWizardState.wizardInputsArray.push(secondRef);
    }
  };

  const updatePackagesOnReflection = () => {
    const packageState = props.wizardState.packageWizardState;
    const { isAddAFlightChecked, isAddACarChecked } = hotelWizardState;

    if (isAddACarChecked && isAddAFlightChecked) {
      packageState.updateSubLOBState(PackagesSubLobs.FLIGHT_HOTEL_CAR);
    } else if (isAddACarChecked) {
      packageState.updateSubLOBState(PackagesSubLobs.HOTEL_CAR);
    } else if (isAddAFlightChecked) {
      packageState.updateSubLOBState(PackagesSubLobs.FLIGHT_HOTEL);
    }
  };

  const toggleDatePicker = () => {
    setAutoOpenCalendar(!autoOpenCalendar);
  };

  const travelersConfig =
    hotelWizardState.isAddACarChecked || hotelWizardState.isAddAFlightChecked ? config.travelersPackage : undefined;

  const getLayoutTravelersInline = (alignment: "end" | "start") => (
    <UitkLayoutFlex data-testid="travelers-inline-link" alignItems={alignment} justifyContent={alignment} space="six">
      <>
        <UitkLayoutFlexItem alignSelf={alignment}>
          <Travelers
            lobState={hotelWizardState}
            colSpan={travelersColSpan}
            noRehydratationValues={NO_REHYDRATATION_VALUES}
            triggerType={TravelersTriggerTypes.LINK_BUTTON}
            config={travelersConfig}
          />
        </UitkLayoutFlexItem>
      </>
    </UitkLayoutFlex>
  );

  const formElements = {
    main: {
      errorSummary: (
        <LobErrorSummary
          locHeadingToken={getLocError(false, hotelWizardState)!}
          locHeadingArgs={moreThanOneError() && [numberOfErrors]}
          locLinkToken={getLocError(true, hotelWizardState)}
          linkClickFocusId="roundtrip-error"
          inputErrorRef={errorInputRef}
          colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
          setInputsRefs={setInputsRefs}
        />
      ),
      destinationField: (
        <Location
          originField={false}
          destinationField
          lobState={hotelWizardState}
          colSpan={locationColSpan}
          setInputsRefs={setInputsRefs}
          globalConfig={globalWizardState.config}
          shouldHideHiddenInputs
        />
      ),
      dates: (
        <Dates
          lobState={hotelWizardState}
          colSpan={datesColSpan}
          setInputsRefs={setInputsRefs}
          autoOpenCalendar={autoOpenCalendar}
          toggleDatePicker={toggleDatePicker}
        />
      ),
      travelers: (
        <Travelers
          lobState={hotelWizardState}
          colSpan={travelersColSpan}
          noRehydratationValues={NO_REHYDRATATION_VALUES}
          config={travelersConfig}
        />
      ),
    },
    addFlight: {
      flightCheckbox: (
        <UitkLayoutGridItem colSpan={addFlightColSpan}>
          <div>
            <HotelAddFlightCheckbox
              hotelWizardState={hotelWizardState}
              onChange={onAddFlightCheckboxChange}
              updatePackageOnReflection={updatePackagesOnReflection}
              analytics={props.analytics}
            />
          </div>
        </UitkLayoutGridItem>
      ),
      flightLocation: (
        <UitkLayoutGrid columns={mainGridColumns}>
          <UitkLayoutGridItem colSpan={locationColSpan}>
            <div>
              <Location
                originField
                destinationField={false}
                lobState={hotelWizardState}
                setInputsRefs={setInputsRefs}
              />
            </div>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      ),
    },
    addCar: {
      addCarCheckbox: (
        <UitkLayoutGridItem colSpan={addCarColSpan}>
          <div>
            <HotelAddCarCheckbox
              hotelWizardState={hotelWizardState}
              updatePackageOnReflection={updatePackagesOnReflection}
              analytics={props.analytics}
            />
          </div>
        </UitkLayoutGridItem>
      ),
    },
    showFilters: {
      hotelFilters: (
        <UitkLayoutGrid columns={mainGridColumns}>
          <UitkLayoutGridItem colSpan={hotelFiltersColSpan}>
            <div>
              <WizardHotelPWAFilter
                changeFormProperties={hotelWizardState.changeFormFilterProperties}
                checkboxFilterChecks={hotelWizardState.checkboxFilterChecks}
                advancedOptionsFilterVisible={hotelWizardState.advancedOptionsFilterVisible}
              />
            </div>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      ),
    },
    showPackageRates: {
      packageRatesCheckbox: (
        <HotelPackageRatesTermsCheckbox
          hotelWizardState={hotelWizardState}
          percentSave={percentSave}
          packageRatesTermsUrl={packageRatesTermsUrl}
          isChecked={hotelWizardState.isPackageRatesTermsChecked}
          subTabType={HotelRatesType.PACKAGE_RATES}
        />
      ),
    },
    showMultiplePlaces: {
      multiplePlacesCheckbox: (
        <MultiplePlaces
          hotelWizardState={hotelWizardState}
          disabled={isMultiplePlacesDisabled}
          isChecked={hotelWizardState.isMultiplePlacesChecked}
          colSpan={multiplePlacesColSpan}
        />
      ),
      multiplePlacesDiscalimer: (
        <UitkLayoutGridItem colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}>
          <div aria-live="polite">
            <UitkText size={300}>{formatText(disclaimerMultiplePlacesLocKey)}</UitkText>
          </div>
        </UitkLayoutGridItem>
      ),
    },
    hotelsPWPToggle: {
      viewOnSmallAndMediumViewPort: (
        <UitkLayoutFlex justifyContent={"start"}>
          <UitkSpacing margin={{ blockstart: "two" }}>
            <div>
              <HotelAddPWPToggle hotelWizardState={hotelWizardState} />
            </div>
          </UitkSpacing>
        </UitkLayoutFlex>
      ),
      viewOnLargeViewPort: (
        <UitkLayoutGridItem colSpan={addCarColSpan} colEnd={13}>
          <UitkLayoutFlexItem>
            <UitkLayoutFlex justifyContent="end">
              <HotelAddPWPToggle hotelWizardState={hotelWizardState} />
            </UitkLayoutFlex>
          </UitkLayoutFlexItem>
        </UitkLayoutGridItem>
      ),
    },
    hotelPackagesToggle: {
      viewOnSmallAndMediumViewPort: <HotelPackageRatesToggle hotelWizardState={hotelWizardState} />,
      viewOnLargeViewPort: (
        <UitkLayoutGrid columns={mainGridColumns}>
          <HotelPackageRatesToggle hotelWizardState={hotelWizardState} />
        </UitkLayoutGrid>
      ),
    },
  };

  const renderMainFormContent = (
    isInsideMultiLOBPackageWizard: boolean,
    isPackageRatesSubTab: boolean,
    isNewSearchExperience: boolean
  ) => (
    <>
      <UitkSpacing margin={{ blockstart: "three" }}>
        <UitkLayoutGrid columns={mainGridColumns} space="three">
          {numberOfErrors > 0 && formElements.main.errorSummary}
          {showDestinationField && formElements.main.destinationField}
          {formElements.main.dates}
          {!displayTravelersInline && isBiasedWizardEmpty && formElements.main.travelers}

          {!isPackageRatesSubTab && !isNewSearchExperience && (
            <>
              {enableMultiplePlaces &&
                isMultiplePlacesDisabled &&
                formElements.showMultiplePlaces.multiplePlacesDiscalimer}
              {enableAddFlight && !isInsideMultiLOBPackageWizard && formElements.addFlight.flightCheckbox}
              {enableAddCar && !isInsideMultiLOBPackageWizard && formElements.addCar.addCarCheckbox}
              {enableMultiplePlaces && formElements.showMultiplePlaces.multiplePlacesCheckbox}
              {enableHotelsPWPToggle && (
                <ExperimentComponent name="ETS_POINTS_TOGGLE">
                  <ExperimentControl />
                  <ExperimentVariant bucket={1}>
                    {isAgent && (
                      <ExperimentComponent name="Delta_BSA_Loyalty_Toggle">
                        <ExperimentControl />
                        <ExperimentVariant bucket={1}>
                          <Viewport>
                            <ViewSmall />
                            <ViewMedium />
                            <ViewLarge>{formElements.hotelsPWPToggle.viewOnLargeViewPort}</ViewLarge>
                          </Viewport>
                        </ExperimentVariant>
                      </ExperimentComponent>
                    )}
                    {!isAgent && (
                      <Viewport>
                        <ViewSmall />
                        <ViewMedium />
                        <ViewLarge>{formElements.hotelsPWPToggle.viewOnLargeViewPort}</ViewLarge>
                      </Viewport>
                    )}
                  </ExperimentVariant>
                </ExperimentComponent>
              )}
            </>
          )}
        </UitkLayoutGrid>
      </UitkSpacing>

      {enableNewTaapShopExperience && (
        <Viewport>
          <ViewSmall>{formElements.hotelPackagesToggle.viewOnSmallAndMediumViewPort}</ViewSmall>
          <ViewMedium>{formElements.hotelPackagesToggle.viewOnSmallAndMediumViewPort}</ViewMedium>
          <ViewLarge>{formElements.hotelPackagesToggle.viewOnLargeViewPort}</ViewLarge>
        </Viewport>
      )}

      {isPackageRatesSubTab && formElements.showPackageRates.packageRatesCheckbox}

      {!isPackageRatesSubTab && !isNewSearchExperience && (
        <>
          {isAddFlightActive && formElements.addFlight.flightLocation}
          {hotelWizardState.config.includeFiltersHotelPWAWizard && formElements.showFilters.hotelFilters}
        </>
      )}

      {enableHotelsPWPToggle && (
        <ExperimentComponent name="ETS_POINTS_TOGGLE">
          <ExperimentControl />
          <ExperimentVariant bucket={1}>
            {isAgent && (
              <ExperimentComponent name="Delta_BSA_Loyalty_Toggle">
                <ExperimentControl />
                <ExperimentVariant bucket={1}>
                  <Viewport>
                    <ViewSmall>{formElements.hotelsPWPToggle.viewOnSmallAndMediumViewPort}</ViewSmall>
                    <ViewMedium>{formElements.hotelsPWPToggle.viewOnSmallAndMediumViewPort}</ViewMedium>
                    <ViewLarge />
                  </Viewport>
                </ExperimentVariant>
              </ExperimentComponent>
            )}
            {!isAgent && (
              <Viewport>
                <ViewSmall>{formElements.hotelsPWPToggle.viewOnSmallAndMediumViewPort}</ViewSmall>
                <ViewMedium>{formElements.hotelsPWPToggle.viewOnSmallAndMediumViewPort}</ViewMedium>
                <ViewLarge />
              </Viewport>
            )}
          </ExperimentVariant>
        </ExperimentComponent>
      )}
    </>
  );

  const currentSubTab = hotelWizardState.hotelTypeSubTab;
  const handleSubTabChange = (hotelRateType: HotelRatesType) => {
    props.wizardState.hotelWizardState.updateHotelTypeSubTab(hotelRateType);
  };

  const pageName = page ? page.pageName : "unknown";
  const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
  const linkName = "HotelSearch";
  const sectionName = "SQM";
  const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;

  const extraTracking: any = {
    schemaName: "referrer",
    messageContent: {
      referrerId: rfrrid,
      eventType: Action.CLICK,
    },
  };

  const pageHeading = props.compositionStore?.composition?.pageHeading;
  const pageSubHeadline = props.compositionStore?.composition?.pageSubHeadline;

  const titleFromComposition = (options.titleFromPageHeader && pageHeading) || undefined;
  const subTitleFromComposition = (options.subTitleFromPageSubHeader && pageSubHeadline) || undefined;

  if (enableNewTaapShopExperience) {
    handleSubTabChange(HotelRatesType.NEW_PROPERTY_SEARCH);
  }

  const sendFormSubmitEvent = () => {
    if (!enableNewTaapShopExperience) {
      return;
    }
    const newTaapSearchSubmittedEvent = {
      search_request: {
        search_query: hotelWizardState.location.destination.value,
        trip_start_date: formatDate(hotelWizardState.date.start, { raw: "yyyy-MM-dd" }),
        trip_end_date: formatDate(hotelWizardState.date.end, { raw: "yyyy-MM-dd" }),
        adult_count: hotelWizardState.hotelTravelersMetadata.numOfAdults,
        child_count: hotelWizardState.hotelTravelersMetadata.numOfChildren,
        room_count: hotelWizardState.hotelTravelersMetadata.numOfRooms,
      },
      event: {
        event_version: "1.0.0",
        event_name: "search.submitted",
        event_type: "Interaction",
        event_category: "Search",
      },
      experience: {
        page_product_line: "Lodging",
      },
      timestamps: {
        event_timestamp: Date.now(),
      },
      point_of_sale: {
        eg_brand_name: "Expedia Partner Solutions",
        sub_brand_name: "TAAP",
      },
    };
    track(newTaapSearchSubmittedEvent);
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    sendFormSubmitEvent();
    hotelWizardState.submit(event);
  };

  return (
    <form
      noValidate
      className="WizardHotelPWAv2"
      id={elementId}
      data-fm={getFmId(templateComponent)}
      data-fm-title-id={fmTitleId}
      onSubmit={handleSubmitForm}
      action={hotelWizardState.formAction}
    >
      <ExperimentComponent name="Taapestry_Migration_Test_AA_US_Expuserid">
        <ExperimentControl />
        <ExperimentVariant bucket={1} />
      </ExperimentComponent>
      <MultiLOBPackageWizardContext.Consumer>
        {({ isInsideMultiLOBPackageWizard }) => {
          if (isInsideMultiLOBPackageWizard) {
            props.wizardState.hotelWizardState.toggleMultiplePlacesCheckbox({ stateToForce: false });
          }

          return (
            <>
              {hotelWizardState.config.heading.fixedTitle && (
                <LobTitle lobState={hotelWizardState} customTitle={titleFromComposition} />
              )}
              <LobSubtitle lobState={hotelWizardState} customSubTitle={subTitleFromComposition} />

              {displayTravelersInline && (
                <Viewport>
                  <ViewSmall>{getLayoutTravelersInline("start")}</ViewSmall>
                  <ViewMedium>{getLayoutTravelersInline("end")}</ViewMedium>
                </Viewport>
              )}

              {/* with new property search on render the grid directly as there are no tabs for desktop devices */}
              {enableNewTaapShopExperience && renderMainFormContent(isInsideMultiLOBPackageWizard, false, true)}

              {/* with New Property Search disabled and package rates ON render the grid with the respective tabs */}
              {!enableNewTaapShopExperience && enablePackageRates && (
                <HotelSubTabs
                  currentSubTab={currentSubTab}
                  onTabSelect={handleSubTabChange}
                  renderTabChildren={renderMainFormContent}
                  enablePackageRates={enablePackageRates}
                />
              )}

              {/* with New Property Search and package rates disabled render the grid directly as there are no tabs */}
              {!enableNewTaapShopExperience &&
                !enablePackageRates &&
                renderMainFormContent(isInsideMultiLOBPackageWizard, false, false)}

              <SubmitRow
                lobState={hotelWizardState}
                rfrr="SearchClick_Hotel"
                extraUISTracking={extraTracking}
                globalConfig={globalWizardState.config}
                disabled={
                  currentSubTab === HotelRatesType.PACKAGE_RATES && !hotelWizardState.isPackageRatesTermsChecked
                }
              />

              <HotelHiddenSearchData hotelWizardState={hotelWizardState} />
            </>
          );
        }}
      </MultiLOBPackageWizardContext.Consumer>
    </form>
  );
});

export default WizardHotelPWAv2View;
