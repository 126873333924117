import { observer } from "mobx-react";
import { HotelWizardStateProps } from "../../typings";
import { HotelRatesType } from "stores/wizard/state/typings";
import { withStores } from "src/stores";
import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { renderInputs } from "./renderInputs";
import { getPropertyFilterConfig } from "./configs/getPropertyFilterConfig";
import { getContextFilterConfig } from "./configs/getContextFilterConfig";
import { getRegionId, getSort } from "./inputs";
import { InputMapper } from "./typings";

export interface HotelHiddenSearchDataProps extends HotelWizardStateProps {
  propertyFilters: PropertyFiltersStore;
  context: ExtendedContextStore;
  staticInputs?: InputMapper;
}

export const HotelHiddenSearchData = withStores(
  "context",
  "propertyFilters"
)(
  observer((props: HotelHiddenSearchDataProps) => {
    const { staticInputs, hotelWizardState, context, propertyFilters } = props;
    const {
      isAddACarChecked,
      isAddAFlightChecked,
      hotelTypeSubTab,
      hotelPackageType,
      hotelTabSelected,
      sourceIdSelected,
      config,
      isPayWithPointsChecked,
      isPackageRatesToggleVisible,
      isPackageRatesIncluded,
    } = hotelWizardState;

    const { filters: contextFilters } = getContextFilterConfig(context);

    const { filters: propertyFiltersMap, inputs: propertyFiltersInputs } = getPropertyFilterConfig(propertyFilters);

    const travelerTypeOverride = config.travelerType ? [config.travelerType] : undefined;

    return renderInputs({
      ...staticInputs,
      ...propertyFiltersInputs,
      packageType: {
        isVisible: hotelTypeSubTab !== HotelRatesType.PACKAGE_RATES && (isAddAFlightChecked || isAddACarChecked),
        testId: "package-type",
        values: [hotelPackageType],
      },
      regionId: getRegionId(hotelWizardState, propertyFiltersMap.regionId),
      sort: getSort(!hotelTabSelected, contextFilters.sort, propertyFiltersMap.sort),
      selected: {
        testId: "source-id",
        values: [sourceIdSelected],
      },
      latLong: {
        testId: "lat-long",
        values: [hotelWizardState.location.destination.metaData.latLong],
      },
      chain: {
        testId: "hotel-chain",
        values: [config.hotelChainID],
      },
      hotelName: {
        testId: "hotel-name",
        values: [config.hotelName],
      },
      group: {
        testId: "hotel-group",
        values: [config.hotelID],
      },
      amenities: {
        values: propertyFiltersMap.amenities || [contextFilters.amenity],
      },
      paymentType: {
        values: [config.paymentTypePreset].concat(propertyFiltersMap.paymentType || [contextFilters.paymentType]),
      },
      travelerType: {
        values: travelerTypeOverride || propertyFiltersMap.travelerType || [contextFilters.travelerType],
      },
      star: {
        values: propertyFiltersMap.star || [contextFilters.star],
      },
      lodging: {
        values: propertyFiltersMap.lodging || [contextFilters.propertyType],
      },
      propertyStyle: {
        values: [contextFilters.propertyStyle],
      },
      useRewards: {
        values: [String(isPayWithPointsChecked)] || "false",
      },
      rate_type: {
        isVisible: isPackageRatesToggleVisible && !isPackageRatesIncluded,
        values: ["standalone"],
      },
    });
  })
);
