import * as React from "react";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkTertiaryButton } from "@egds/react-core/button";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkLink } from "@egds/react-core/link";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import WizardForm, { WizardFormProps } from "./WizardForm";
import { travelersSummary } from "src/components/shared/TravelersField/utils";
import { useExpando } from "../../hooks/useExpando";
import { StickyCTAMobile } from "components/flexComponents/WizardHotelPWA/components/StickyCTAMobile";

interface WizardProps extends Omit<WizardFormProps, "isMobile"> {
  toggle?: () => void;
  desktopAutoOpenCalendar?: boolean;
}

export const WizardMobile: React.FC<WizardProps> = observer((props) => {
  const { toggle, isExpanded, expand } = useExpando(Boolean(props.desktopAutoOpenCalendar));
  const { formatText } = useLocalization();
  const [wizardContainerIsOutOfWindow, setWizardContainerIsOutOfWindow] = React.useState(false);
  const wizardSmallViewContainer = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = React.useCallback(() => {
    if (wizardSmallViewContainer && wizardSmallViewContainer.current) {
      const currentWizardRef = wizardSmallViewContainer.current.getBoundingClientRect().bottom;
      setWizardContainerIsOutOfWindow(currentWizardRef < 0);
    }
  }, [wizardSmallViewContainer, setWizardContainerIsOutOfWindow]);

  const getModuleNameForTracking = () => {
    // append sticky to the module name if needed
    // doesn't look at the device information since you can't interact with the wizard it if you don't see it.
    return wizardContainerIsOutOfWindow ? "wizard-hotel-pwa-v2-row-sticky" : "wizard-hotel-pwa-v2-row";
  };

  return (
    <>
      <StickyCTAMobile
        isSticky={wizardContainerIsOutOfWindow}
        moduleName={getModuleNameForTracking()}
        linkName="Scroll to wizard v2 expando and open it"
        submitButtonText={formatText("wizard.sticky.cta.label")}
        scrollTarget={wizardSmallViewContainer}
        expandWizard={expand}
      />
      <div ref={wizardSmallViewContainer} id={props.templateComponent.metadata.id} data-testid="wizard-mobile">
        <UitkCard data-stid="section-hotel-search">
          <UitkCardContentSection padded={false}>
            {isExpanded ? (
              <ExpandedWizard {...props} toggle={toggle} />
            ) : (
              <CollapsedWizard toggle={toggle} wizardState={props.wizardState} />
            )}
          </UitkCardContentSection>
        </UitkCard>
      </div>
    </>
  );
});

const ExpandedWizard: React.FC<WizardProps> = ({ ...props }) => {
  const { formatText } = useLocalization();

  return (
    <UitkSpacing padding={{ inline: "three", blockstart: "three" }}>
      <div>
        <WizardForm {...props} isDesktop={false} />
        <UitkSpacing padding={{ blockstart: "three" }}>
          <div>
            <UitkTertiaryButton onClick={props.toggle} isFullWidth>
              {formatText("DestinationsByAffinity.priceDialogCloseButton")}
            </UitkTertiaryButton>
          </div>
        </UitkSpacing>
      </div>
    </UitkSpacing>
  );
};

interface ExpandedWizardHandleProps extends Pick<WizardProps, "wizardState" | "toggle"> {}

const CollapsedWizard: React.FC<ExpandedWizardHandleProps> = ({ wizardState, toggle }) => {
  const { formatText } = useLocalization();

  const { hotelWizardState } = wizardState;
  const travelersConfig = hotelWizardState.config.travelers;
  const travelers = hotelWizardState.travelers.hotel;
  const travelersMetadata = hotelWizardState.hotelTravelersMetadata;

  const { numOfAdults, numOfChildren, numOfInfants } = travelersMetadata;
  const { withInfants } = travelersConfig;

  const numOfTravelers = numOfAdults + numOfChildren + (withInfants ? numOfInfants : 0);
  const summary = numOfTravelers !== 0 ? travelersSummary(travelersConfig, travelers.rooms, numOfTravelers) : undefined;

  return (
    <UitkSpacing padding={{ inline: "three" }}>
      <UitkLink onClick={toggle}>
        <UitkLayoutFlex onClick={toggle}>
          <UitkLayoutFlexItem alignSelf="center">
            <UitkSpacing margin={{ inlineend: "two" }}>
              <UitkIcon
                id="expando-search-icon"
                size={UitkIconSize.MEDIUM}
                name="search"
                title={formatText("wizard.mobile.label.hotel")}
                description={formatText("wizard.mobile.label.hotel")}
              />
            </UitkSpacing>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ block: "two", inlinestart: "one" }}>
              <UitkText align="left" overflow="truncate">
                <UitkText data-testid="expando-location-title" overflow="truncate" size={400} weight="medium">
                  {hotelWizardState.location.destination.value}
                </UitkText>
                <>
                  <UitkSpacing padding={{ inlineend: "six" }}>
                    <UitkText inline={true} size={200} weight="medium">
                      {formatText("wizard.expando.selectDates")}
                    </UitkText>
                  </UitkSpacing>
                  {summary && (
                    <UitkSpacing padding={{ inlineend: "two" }}>
                      <UitkText
                        data-testid="expando-travelers-count"
                        inline={true}
                        size={200}
                        weight="medium"
                        aria-label={`, ${summary}`}
                      >
                        {summary}
                      </UitkText>
                    </UitkSpacing>
                  )}
                </>
              </UitkText>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkLink>
    </UitkSpacing>
  );
};
