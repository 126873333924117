import { WizardHotelPWAv2HotwireView } from "../views/WizardHotelPWAv2HotwireView";
import { WizardHotelPWAv2View } from "../views/WizardHotelPWAv2View";
import { WizardHotelPWAv2RowView } from "../views/WizardHotelPWAv2RowView/WizardHotelPWAv2RowView";
import { HotelWizardState } from "stores/wizard/state";
import { NoRehydratationValues } from "components/shared/TravelersField/typings";
import { locKeys } from "components/flexComponents/WizardHotelPWA/components/l10n";

type FormatText = (key: string, ...args: any[]) => string;

enum WizardHotelPWAv2Views {
  DEFAULT = "default",
  HOTWIRE = "hotwire",
  ROW = "row",
}

export enum DateState {
  START = "startDate",
  END = "endDate",
}

/**
 * Values used if no rehydratation(JS disabled)
 */
export const NO_REHYDRATATION_VALUES: NoRehydratationValues = {
  numOfTravelers: 2,
  name: "adults",
};

export const getComponentFromView = (view?: string) => {
  switch (view) {
    case WizardHotelPWAv2Views.HOTWIRE:
      return WizardHotelPWAv2HotwireView;
    case WizardHotelPWAv2Views.ROW:
      return WizardHotelPWAv2RowView;
    default:
      return WizardHotelPWAv2View;
  }
};

export const getLocError = (isLink: boolean, hotelWizardState: HotelWizardState) => {
  const { config } = hotelWizardState;

  if (!isLink) {
    return hotelWizardState.numberOfErrors
      ? config.form.submitButton.locWarningsWithNumberToken
      : config.form.submitButton.locWarningsToken;
  }

  return hotelWizardState.moreThanOneError()
    ? config.form.submitButton.locFirstWarningLinkToken
    : config.form.submitButton.locWarningLinkToken;
};

/**
 * When user add a flight to the current trip search,
 * a origin field is displayed so as a legend for multiple places (if enabled)
 * Fields below add a flight checkbox should be visible in mobile view
 * Following values are taken from UDS tokens:
 * http://uds.ch.lab.stockyard.io/expedia/tokens/#foundation-breakpoint
 */
export const onAddFlightCheckboxChange = () => {
  const VIEWPORT_MAX_SMALL_BREAKPOINT = 575;
  const SPACE_ABOVE_WIZARD_IN_PIXELS = 200;
  const SPACE_LEFT_WIZARD_IN_PIXELS = 0;
  const SCROLL_BEHAVIOR = "smooth";
  const { innerWidth: viewportSize } = window;

  if (viewportSize < VIEWPORT_MAX_SMALL_BREAKPOINT) {
    window.scrollTo({
      top: SPACE_ABOVE_WIZARD_IN_PIXELS,
      left: SPACE_LEFT_WIZARD_IN_PIXELS,
      behavior: SCROLL_BEHAVIOR,
    });
  }
};

const isValidDate = (date: Date) => {
  return date && date.toString() !== "Invalid Date";
};

export const validate = (
  key: string,
  hotelWizardState: HotelWizardState,
  formatText: FormatText,
  hasEmptyDates: boolean
) => {
  const checkInDate = hasEmptyDates ? new Date(hotelWizardState.startDate) : hotelWizardState.date?.start;
  const checkOutDate = hasEmptyDates ? new Date(hotelWizardState.endDate) : hotelWizardState.date?.end;

  if (key === DateState.START && !isValidDate(checkInDate)) {
    return formatText(locKeys.invalidFromDate);
  }

  if (key === DateState.END && !isValidDate(checkOutDate)) {
    return formatText(locKeys.invalidToDate);
  }

  if (key === DateState.END && isValidDate(checkInDate)) {
    const maxEndDate = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate() + 28);

    if (checkOutDate < checkInDate) {
      return formatText(locKeys.invalidDateRangeEndBeforeStart);
    }

    if (checkOutDate > maxEndDate) {
      return formatText(locKeys.invalidDateRangeEndOverLimit);
    }
  }

  return "";
};

export const getErrorList = (
  startDateError: string,
  endDateError: string,
  event?: React.FormEvent<HTMLFormElement>
) => {
  const errors = [];

  if (startDateError) {
    errors.push(startDateError);
  }

  if (endDateError) {
    errors.push(endDateError);
  }

  if (errors.length > 0 && event && typeof event.preventDefault === "function") {
    event.preventDefault();
  }

  return errors;
};
