import * as React from "react";
import { observer } from "mobx-react";
import { locKeys } from "components/flexComponents/WizardHotelPWA/components/l10n";
import { HotelWizardStateProps } from "components/flexComponents/WizardHotelPWAv2/typings";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

export interface HotelAddCarCheckboxProps extends HotelWizardStateProps {
  updatePackageOnReflection: () => void;
  analytics: AnalyticsStore | undefined;
}

export const HotelAddCarCheckbox = observer(
  ({ hotelWizardState, updatePackageOnReflection, analytics }: HotelAddCarCheckboxProps) => {
    const { formatText } = useLocalization();

    const rfrrAddCarCheckbox = "SoftPackage_Car";
    const moduleName = "WizardHotelPWAv2";

    const getAddCarCheckboxTrackingInfo = (rfrr: string): FlexTrackingInfo => {
      return {
        moduleName,
        action: Action.CLICK,
        rfrr,
      };
    };

    const handleChange = () => {
      hotelWizardState.toggleAddACarCheckbox();

      updatePackageOnReflection();

      sendDelayedTrackEvent(getAddCarCheckboxTrackingInfo(rfrrAddCarCheckbox), analytics);
    };

    return (
      <UitkLayoutFlexItem>
        <UitkCheckbox
          id="add-car-switch"
          name="add-car-switch"
          value={String(hotelWizardState.isAddACarChecked)}
          checked={hotelWizardState.isAddACarChecked}
          onChange={handleChange}
          disabled={hotelWizardState.isMultiplePlacesChecked}
        >
          {formatText(locKeys.addCarLabel)}
        </UitkCheckbox>
      </UitkLayoutFlexItem>
    );
  }
);
